export default {
  header: 'Surveys',
  emptyStateHeader:
    'Collect, analyze, and gain insights from customer feedback',
  emptyStateText: 'Start by creating your first survey!',
  addSurvey: 'Add Survey',
  dialogSaveButton: 'Save',
  dialogDeleteButton: 'Delete',
  dialogTitleDetails: 'Survey details',
  dialogTitleDelete: 'Delete survey',
  dialogTextDelete: `Deleting this survey will remove all associated data.`,
  dialogQuestionDelete: 'Are you sure you want to proceed with deletion?',
  customLinkWarning:
    'This survey is also attached to a custom link, which will also be removed from your account.',
  slugValidationMessage:
    'Input must be alphanumeric, can include hyphens, and be up to 20 characters long.',
  slugUniqueMessage: 'Short link has to be unique, try changing it',
  tableNameColumn: 'Name',
  tableTypeColumn: 'Type',
  tableCreatedColumn: 'Created at',
  menuButtonEdit: 'Edit Survey',
  menuButtonDelete: 'Delete Survey',
  menuButtonShare: 'Copy Link',
  menuButtonExportResponses: 'Export Responses',
  menuButtonCSAT: 'CSAT',
  menuButtonNPS: 'NPS',
  nameLabel: 'Name',
  namePlaceholder: 'Name',
  typeLabel: 'Type',
  typePlaceholder: 'Type',
  generalError: 'Something went wrong, please try again later',
};
