<script setup lang="ts">
import { ref } from 'vue';
import {
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogForm,
  WsDialogSection,
  WsAlert,
} from '@mfl/common-components';
import {
  DeleteResponse,
  DeleteSurveyLinkResponse,
  Survey,
  surveysGateway,
} from '@msl/surveys-gateway-sdk';
import strings from './surveys.strings';

const props = defineProps<{
  dialog: IDialog<Survey, DeleteResponse>;
}>();

const busy = ref(false);
const survey = ref<Survey>({
  id: '',
  linkId: '',
});

if (props.dialog.input) {
  survey.value = props.dialog.input;
}

async function deleteSurvey() {
  busy.value = true;
  const res = await surveysGateway.delete({ ids: [survey.value.id || ''] });

  let link: DeleteSurveyLinkResponse = { deleteCount: 0 };
  if (survey.value.linkId) {
    link = await surveysGateway.deleteSurveyLink({
      linkId: survey.value.linkId,
    });
  }

  if (res?.deleteCount && (!survey.value.linkId || link.deleteCount)) {
    props.dialog.close(res);
  } else {
    console.error('Delete survey failed');
  }
  busy.value = false;
}
</script>

<template>
  <WsDialogForm @submit="deleteSurvey">
    <WsDialogSection class="delete-confirmation-section">
      <b>{{ strings.dialogTextDelete }}</b>
      <WsAlert
        v-if="survey.linkId"
        aid="DELETE_SURVEY_DIALOG_ALERT"
        :open="true"
        type="warning"
      >
        {{ strings.customLinkWarning }}
      </WsAlert>
      {{ strings.dialogQuestionDelete }}
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="SURVEY_DIALOG_CANCEL_BUTTON"
        label="Cancel"
        color="gray-500"
        variant="outlined"
        @click="props.dialog.close()"
      />
      <WsButton
        type="submit"
        color="danger"
        aid="SURVEY_DIALOG_DELETE_BUTTON"
        :label="strings.dialogDeleteButton"
        :loading="busy"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style scoped>
.delete-confirmation-section {
  max-width: 400px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  gap: 17px;
}
</style>
