<script setup lang="ts">
import {
  IDialog,
  WsAlert,
  WsDialogSection,
  WsButton,
  WsDialogForm,
} from '@mfl/common-components';

const props = defineProps<{
  dialog: IDialog<string, boolean>;
}>();
</script>

<template>
  <WsDialogForm @submit="props.dialog.close(true)">
    <WsDialogSection class="d-s">
      <WsAlert
        aid="DELETE_LINK_SURVEY_DIALOG_ALERT"
        class="alert"
        variant="flat"
        type="warning"
        :open="true"
      >
        {{ props.dialog.input }}
      </WsAlert>
      <WsButton
        aid="DELETE_LINK_SURVEY_DIALOG_CANCEL_BUTTON"
        label="Cancel"
        color="gray-400"
        variant="flat"
        size="sm"
        @click="props.dialog.close()"
      >
      </WsButton>
      <WsButton
        type="submit"
        color="primary"
        variant="flat"
        size="sm"
        aid="DELETE_LINK_SURVEY_DIALOG_PROCEED_BUTTON"
        label="Proceed"
      >
      </WsButton>
    </WsDialogSection>
  </WsDialogForm>
</template>

<style scoped>
.d-s {
  margin-top: -28px;
  padding: 0px;
  display: flex;
  justify-items: center;
  background-color: #fffbf7;
}
</style>
